<template>
  <v-container fluid>
    <!-- start of editor -->
    <editor
      :seriesData="seriesArray"
      :defaultSeries="defaultSeries"
      :usersData="allUsersData"
      :branchesData="branchData"
      :docCreate="docCreate"
      :docCopyTo="docCopyTo"
      :setup="setup"
      :tableRows="tableRows"
      :initial="dataItem"
      :tableData="tableData"
      :objectName="objectName"
      :activeObject="activeObject"
      :originNo="originNo"
      :originName="originName"
      :withPayment="withPayment"
      @data="save"
    ></editor>
    <!-- end of editor -->

    <v-row dense>
      <v-col cols="9">
        <!-- <v-btn color="primary" class="right" @click="sendData">Save</v-btn> -->
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          dense
          outlined
          @change="copyDocTo(docId)"
          color="primary"
          v-model="docId"
          :items="copyDocs"
          item-text="name"
          item-value="id"
          label="Copy To"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <!-- snackbar -->
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
import Editor from "../_components/create-editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
    branchData() {
      return this.$store.state.branches;
    },
    allUsersData() {
      return this.$store.state.userdata;
    },
  },
  data: () => ({
    dataItem: { parent: false, jdt1: [] },
    docCreate: false,
    docCopyTo: false,
    tenantData: {},
    editMode: false,
    docStatus: null,
    currencies: [],
    setup: {},
    defaultSeries: {},
    branchesArray: [],
    seriesArray: [],
    saleDocs: [],
    copyDocs: [],
    objectName: null,
    docId: null,
    record: {},
    tableRows: [],
    tableData: [],
    activeObject: null,
    jdt1: [],
    LocTotal: null,
    originNo: null,
    originName: null,
    withPayment: null,
  }),
  watch: {
    "$route.params.objectId": {
      handler: "formSettings",
      immediate: true,
    },
    "$route.params.id": {
      handler: "getDocs",
      immediate: true,
    },
  },
  methods: {
    save(data) {
      var isDoc = this.$route.query.isDoc;
      data.ObjType = this.activeObject;
      const activeObject = this.activeObject;
      const url =
        "marketing-doc-update/" +
        activeObject +
        "/" +
        data.id +
        `?isDoc=${isDoc}`;
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.$router.push(`/inventory/transactions/${activeObject}`);
          } else {
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("loader", false);
        });
    },
    copyDocTo(id) {
      var isDoc = this.$route.query.isDoc;
      const BaseObject = this.activeObject;
      const docId = this.dataItem.id;
      const copyToDoc = id;
      this.$router.push(
        `/inventory/doc/copy/${BaseObject}/${docId}/${copyToDoc}?isDoc=${isDoc}`
      );
    },
    formSettings(objectId) {
      this.activeObject = objectId;

      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/form_settings/${objectId}`)
        .then((res) => {
          self.setup = res;
          self.tableRows = res.tabs[0].tableRows;
          self.seriesArray = res.Series;
          self.usersArray = res.users;
          self.branchesArray = res.branches;
          self.BPLId = res.BPLId;
          self.loading = false;
        })
        .catch((err) => {
          console.log(err, "error");
        });

      if (this.$route.query.isDoc == 1 || this.$route.query.isDoc == 2) {
        if (self.docStatus !== "C") {
          if (objectId == 66) {
            // set object Name
            this.copyDocs = [{ name: "Inventory Transfer", id: 67 }];
          }
        }
      }
      // end
      if (this.activeObject !== 13) {
        this.withPayment = "N";
      }
      if (this.activeObject == 13) {
        this.withPayment = "Y";
      }

      // set object name
      this.$store
        .dispatch("get", `/doc_model/${objectId}`)
        .then((res) => {
          self.objectName = res.DocumentName;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getDocs(id) {
      var isDoc = this.$route.query.isDoc;
      this.$store.commit("loader", false);
      if (this.activeObject !== null) {
        const objectId = this.activeObject;
        if (id) {
          const self = this;
          this.$store
            .dispatch(
              "get",
              `/inventory-transactions-data/${objectId}/${id}?isDoc=${isDoc}`
            )
            .then((res) => {
              self.docStatus = res.DocStatus;
              self.dataItem = res;
              self.tableData = res.document_lines;

              self.originName = res.objecttype.DocumentName;
              self.originNo = res.DocNum;
            })
            .catch((err) => {
              console.log(err);
              // this.$refs.snackbar.show(err, "red");
            });
        }
      }
    },
  },
  created() {
    //this.getDocs();
  },
};
</script>